import React from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import "./SectionThree.scss"

function SectionThree() {
  React.useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
      easing: "ease-in-out",
      offset: 50,
    })
  })

  return (
    <div id="fabtech-section-three">
      <div id="container">
        <div className="content">
          <h1>
            Precisa de soluções em ciência de dados e tecnologias customizadas?
          </h1>
          <a href="/contact/#">
            <button>Entre em contato</button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default SectionThree
