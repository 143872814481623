import React from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import "./SectionFive.scss"

const SectionFive = () => {
  React.useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
      easing: "ease-in-out",
    })
  })

  return (
    <div id="fabtech-section-five">
      <div id="content">
        <div className="section-five-text">
          <h1 className="text-h1" data-aos="fade-up">
            Separamos registros de algumas interações com nossos clientes
          </h1>
        </div>
        <div className="section-five-images">
          <div className="section-one-image">
            <div
              className="item-flex-1"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="0"
            >
              <a
                data-flickr-embed="true"
                href="https://flic.kr/s/aHsmQEN3WR"
                alt="FAB Tech: Clique para ir ao albúm no flicker"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://live.staticflickr.com/65535/50325878218_d14b85893d_k.jpg"
                  width="1536"
                  height="2048"
                  alt="FAB Tech: Clique para ir ao albúm no flicker"
                />
              </a>
              <script
                async
                src="//embedr.flickr.com/assets/client-code.js"
                charset="utf-8"
              ></script>
            </div>
            <div
              className="item-flex-2"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-offset="0"
            >
              <a
                data-flickr-embed="true"
                href="https://flic.kr/s/aHsmQEN3WR"
                alt="FAB Tech: Clique para ir ao albúm no flicker"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://live.staticflickr.com/65535/50325878048_5b323e9e56_k.jpg"
                  width="1536"
                  height="2048"
                  alt="IMG_2515"
                />
              </a>
              <script
                async
                src="//embedr.flickr.com/assets/client-code.js"
                charset="utf-8"
              ></script>
            </div>
          </div>
          <div className="section-two-image">
            <div
              className="item-flex-1"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-offset="0"
            >
              <a
                data-flickr-embed="true"
                href="https://flic.kr/s/aHsmQEN3WR"
                alt="FAB Tech: Clique para ir ao albúm no flicker"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://live.staticflickr.com/65535/50325878393_15b2c895fa_k.jpg"
                  width="1536"
                  height="2048"
                  alt="FAB Tech: Clique para ir ao albúm no flicker"
                />
              </a>
              <script
                async
                src="//embedr.flickr.com/assets/client-code.js"
                charset="utf-8"
              ></script>
            </div>
            <div
              className="item-flex-2"
              data-aos="fade-up"
              data-aos-delay="800"
              data-aos-offset="0"
            >
              <a
                data-flickr-embed="true"
                href="https://flic.kr/s/aHsmQEN3WR"
                alt="FAB Tech: Clique para ir ao albúm no flicker"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://live.staticflickr.com/65535/50326719787_85adb577f4_k.jpg"
                  width="1536"
                  height="2048"
                  alt="FAB Tech: Clique para ir ao albúm no flicker"
                />
              </a>
              <script
                async
                src="//embedr.flickr.com/assets/client-code.js"
                charset="utf-8"
              ></script>
            </div>
          </div>
        </div>
        <div className="section-five-button">
          <a
            href="https://flic.kr/s/aHsmQEN3WR"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <button className="btn" data-aos="fade-up">
              veja mais
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default SectionFive
