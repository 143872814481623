import React, { useState, useEffect } from "react"
import { FiPlus, FiMinus } from "react-icons/fi"
import AOS from "aos"
import "aos/dist/aos.css"

import PinnedMenu from "../../../../utils/PinnedMenu"

import soft_development from "../../../../images/illustrations/soft-development.svg"
import data_science from "../../../../images/illustrations/data-science.svg"

import "./SectionTwo.scss"
import "../../../../utils/PinnedMenu/styles.scss"

const SectionTwo = () => {
  const [expandedDigitalProduct, setExpandedDigitalProduct] = useState(false)
  const [expandedDataScience, setExpandedDataScience] = useState(false)
  const [active, setActive] = useState("digital-product")

  const [fixedDesignSprint, setFixedDesignSprint] = useState(false)
  const [fixedDataScience, setFixedDataScience] = useState(false)

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
      easing: "ease-in-out",
      offset: 50,
    })
  })

  useEffect(() => {
    function fixedMenusInTop() {
      const digitalProductMenu = new PinnedMenu(
        "digital-product-menu",
        "digital-product-content"
      )
      const dataScienceMenu = new PinnedMenu(
        "data-science-menu",
        "data-science-content"
      )
      const menuDesktop = new PinnedMenu("collapse-and-slider-menu", "sliders")

      setFixedDesignSprint(
        digitalProductMenu.shouldItBePinned(expandedDigitalProduct)
      )
      if (digitalProductMenu.shouldItBePinned(expandedDigitalProduct))
        digitalProductMenu.pin()
      else digitalProductMenu.unpin()

      setFixedDataScience(dataScienceMenu.shouldItBePinned(expandedDataScience))
      if (dataScienceMenu.shouldItBePinned(expandedDataScience))
        dataScienceMenu.pin()
      else dataScienceMenu.unpin()

      if (menuDesktop.shouldItBePinned(true, true)) menuDesktop.pin(true)
      else menuDesktop.unpin(true)
    }
    window.addEventListener("scroll", fixedMenusInTop)
    fixedMenusInTop()
  }, [expandedDigitalProduct, expandedDataScience])

  return (
    <div id="fabtech-section-two">
      <div id="phases-tech"></div>
      <ul id="collapse-and-slider-menu" className="collapse-menu">
        <li className="collapse-menu-item">
          <button
            id="digital-product-menu"
            className={`collapse-menu-header  ${
              active === "digital-product" ? "active" : ""
            } ${fixedDesignSprint ? "fixed-mobile" : ""}`}
            onClick={() => {
              setExpandedDigitalProduct(!expandedDigitalProduct)
              setActive("digital-product")
            }}
          >
            <h2 className="collapse-menu-title" id="design-sprint">
              DESENVOLVIMENTO DE PRODUTOS DIGITAIS
            </h2>
            {!expandedDigitalProduct ? (
              <FiPlus size={30} />
            ) : (
              <FiMinus size={30} />
            )}
          </button>
          <div
            id="digital-product-content"
            className={`collapse-menu-content 
                  ${expandedDigitalProduct ? "expanded" : "contracted"}`}
          >
            <h3>
              Idealizamos, co-criamos e desenvolvemos produtos digitais para a
              sua empresa.
            </h3>
            <p>
              Descubra o Design Sprint e as possibilidades de desenvolvimento de
              softwares.
            </p>
            <div className="image">
              <img src={soft_development} alt="" />
            </div>
            <div className="button-container">
              <a className="button-container" href="/fabtech/digital-products">
                <button>Saiba mais</button>
              </a>
            </div>
          </div>
        </li>
        <li className="collapse-menu-item">
          <button
            id="data-science-menu"
            className={`collapse-menu-header  ${
              active === "data-science" ? "active" : ""
            } ${fixedDataScience ? "fixed-mobile" : ""}`}
            onClick={() => {
              setExpandedDataScience(!expandedDataScience)
              setActive("data-science")
            }}
          >
            <h2 id="poc" className="collapse-menu-title">
              CIÊNCIA DE DADOS APLICADA
            </h2>
            {!expandedDataScience ? (
              <FiPlus size={30} />
            ) : (
              <FiMinus size={30} />
            )}
          </button>
          <div
            id="data-science-content"
            className={`collapse-menu-content 
                  ${expandedDataScience ? "expanded" : "contracted"}`}
          >
            <h3>Técnicas e motodologias sofisticadas de ciência de dados.</h3>
            <p>
              Descubra como aproveitar o máximo do poder dos dados nas soluções
              Prova de Conceiro (PoC) e Advanced Analytics.
            </p>

            <div className="image">
              <img src={data_science} alt="" />
            </div>
            <div className="button-container">
              <a className="button-container" href="/fabtech/data-science">
                <button>Saiba mais</button>
              </a>
            </div>
          </div>
        </li>
      </ul>
      <ul
        id="sliders"
        className="slider-menu"
        data-aos="fade-up"
        data-aos-delay="250"
      >
        <li
          id="digital-product-lg"
          className={`slider-menu-page ${
            active === "digital-product" ? "active" : ""
          }`}
        >
          <div className="content-container">
            <span className="main-content">
              <div className="texts">
                <h1 className="title">
                  Idealizamos, co-criamos e desenvolvemos produtos digitais para
                  a sua empresa.
                </h1>
                <p className="text">
                  Descubra o Design Sprint e as possibilidades de
                  desenvolvimento de softwares.
                </p>
              </div>
              <div className="image">
                <img src={soft_development} alt="" />
              </div>
            </span>
            <div className="know-more">
              <a href="/fabtech/digital-products/#">
                <button>Saiba mais</button>
              </a>
            </div>
          </div>
        </li>
        <li
          id="data-science-lg"
          className={`slider-menu-page ${
            active === "data-science" ? "active" : ""
          }`}
        >
          <div className="content-container">
            <span className="main-content">
              <div className="texts">
                <h1 className="title">
                  Técnicas e motodologias sofisticadas de ciência de dados.
                </h1>
                <p className="text">
                  Descubra como aproveitar o máximo do poder dos dados nas
                  soluções Prova de Conceiro (PoC) e Advanced Analytics.
                </p>
              </div>
              <div className="image">
                <img src={data_science} alt="" />
              </div>
            </span>
            <div className="know-more">
              <a href="/fabtech/data-science/#">
                <button>Saiba mais</button>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default SectionTwo
