import React from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import "./SectionOne.scss"

const SectionOne = () => {
  React.useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
      easing: "ease-in-out",
      offset: 50,
    })
  })
  return (
    <div id="fabtech-section-one">
      <div id="content">
        <div className="section-one-text">
          <h1 data-aos="fade-right" data-aos-delay="100" className="title">
            FAB tech
          </h1>
          <p data-aos="fade-right" data-aos-delay="500">
            Desenvolvemos soluções em ciência de dados e tecnologias
            customizáveis que aprimoram a performance digital e analítica de
            empresas.
          </p>
        </div>
        <div className="section-one-button">
          <a href="#fabtech-section-two" style={{ textDecoration: "none" }}>
            <button className="btn" type="button">
              Descubra soluções em:
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default SectionOne
